import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar/snack-bar-config";
import { Validador } from "app/models/user.interface";

export class GlobalConstants {
    //public static apiURL: string = "http://localhost/ConstructionCorpAPI/";
    public static apiURL: string = "https://apiconstructioncorp.in-mueblesec.com/";
    public static SnackBarVerticalPosition: MatSnackBarVerticalPosition = "top";
    public static SnackBarHorizonalPosition: MatSnackBarHorizontalPosition = "right";
    public static NameToken: string = "token";
    public static TimeShowSnackBar: number = 3000;
    public static NameNavigation: string = "menu";
    public static OcultarLayout: any =
    {
        layout: {
            navbar   : {
                hidden: true
            },
            toolbar  : {
                hidden: true
            },
            footer   : {
                hidden: true
            },
            sidepanel: {
                hidden: true
            }
        }
    };

}


export const confirmPasswordValidator: ValidatorFn = (
    control: AbstractControl
): ValidationErrors | null => {
    if (!control.parent || !control) {
        return null;
    }

    const password = control.parent.get("password");
    const passwordConfirm = control.parent.get("passwordConfirm");

    if (!password || !passwordConfirm) {
        return null;
    }

    if (passwordConfirm.value === "") {
        return null;
    }

    if (password.value === passwordConfirm.value) {
        return null;
    }

    return { passwordsNotMatching: true };
};