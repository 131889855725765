import {
    HttpClient,
    HttpErrorResponse,
    HttpParams,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalConstants } from "app/common/global-constants";
import {
    CreateUser,
    User,
    UserResponse,
    Validador,
} from "app/models/user.interface";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/internal/operators";

@Injectable({
    providedIn: "root",
})
export class AutenticacionService {
    errorMsg: string;
    navigation: any;
    private _registry: any = "assets/images/avatars/profile.jpg";
    private _fullName: any = "";
    private _onImageChanged: BehaviorSubject<any>;
    private _onImageRegistered: BehaviorSubject<any>;
    private _onFullNameChanged: BehaviorSubject<any>;
    private _onFullNameregistered: BehaviorSubject<any>;

    constructor(private http: HttpClient, private _enrutador: Router) {
        this._onImageRegistered = new BehaviorSubject(null);
        this._onImageChanged = new BehaviorSubject(null);
        this._onFullNameChanged = new BehaviorSubject(null);
        this._onFullNameregistered = new BehaviorSubject(null);
    }

    /**
     * Get onImageChanged
     *
     * @returns {Observable<any>}
     */
    get onImageChanged(): Observable<any> {
        return this._onImageChanged.asObservable();
    }

    /**
     * Get onImageChanged
     *
     * @returns {Observable<any>}
     */
    get onFullNameChanged(): Observable<any> {
        return this._onFullNameChanged.asObservable();
    }

    getImage(): any {
        // Return the sidebar
        return this._registry;
    }

    //obtener la nueva info
    registerImage(imagen): void {
        // Add to the registry
        this._registry = imagen;

        // Notify the subject
        this._onImageRegistered.next(imagen);
    }

    setCurrentImage(imagen): void {
        // Notify the subject
        this._onImageChanged.next(imagen);
    }

    getCurrentImage(): any {
        return this.getImage();
    }

    getFullName(): any {
        // Return the sidebar
        return this._fullName;
    }

    //obtener la nueva info
    registerFullName(fullName:string): void {
        // Add to the registry
        this._fullName = fullName;

        // Notify the subject
        this._onFullNameregistered.next(fullName);
    }

    setCurrentFullName(fullName): void {
        // Notify the subject
        this._onFullNameChanged.next(fullName);
    }

    getCurrentFullName(): any {
        return this.getFullName();
    }

    issLogged() {
        if (localStorage.getItem(GlobalConstants.NameToken) != null)
            return true;
        else return false;
    }

    login(authData: User) {
        const headers = {
            "content-type": "application/json",
        };

        const body = JSON.stringify(authData);
        return this.http
            .post<UserResponse>(GlobalConstants.apiURL + "login", body, {
                headers: headers,
            })
            .pipe(
                map((resp: UserResponse) => {
                    this.saveToken(resp.token);
                    this.verificarSesion();
                    return resp;
                }),
                catchError((err: any) => this.handlerError(err))
            );
    }

    createUser(authData: CreateUser) {
        const headers = {
            "content-type": "application/json",
        };

        const body = JSON.stringify(authData);
        return this.http
            .post<any>(GlobalConstants.apiURL + "newuser", body, {
                headers: headers,
            })
            .pipe(
                map((resp: any) => {
                    return resp;
                }),
                catchError((err: any) => this.handlerError(err)) // en caso de que de error llamamos el método handlerError
            );
    }

    findUserName(email: string) {
        const httpOptions = {
            headers: {
                "content-type": "application/json",
            },
        };
        return this.http
            .get<any>(
                GlobalConstants.apiURL + "principal/findUser/" + email,
                httpOptions
            )
            .pipe(
                map((resp: any) => {
                    return resp;
                }),
                catchError((err: any) => this.handlerError(err)) // en caso de que de error llamamos el método handlerError
            );
    }

    verificarSesion() {
        var token = localStorage.getItem(GlobalConstants.NameToken);
        const httpOptions = {
            headers: {
                "content-type": "application/json",
                Authorization: "Bearer " + token,
            },
        };

        return this.http
            .get<Validador>(
                GlobalConstants.apiURL + "api/verifySession",
                httpOptions
            )
            .pipe(
                map((resp: Validador) => {
                    return resp;
                }),
                catchError((err: any) => {
                    localStorage.removeItem(GlobalConstants.NameToken);
                    this._enrutador.navigateByUrl("/pages/auth/login");
                    return throwError(err["error"].message);
                })
            );
    }

    private getServerErrorMessage(error: HttpErrorResponse): string {
        switch (error.status) {
            case 401: {
                return `Unauthorized: ${error.message}`;
            }
            case 404: {
                return `Not Found: ${error.message}`;
            }
            case 403: {
                return `Access Denied: ${error.message}`;
            }
            case 500: {
                return `Internal Server Error: ${error.message}`;
            }
            default: {
                return `Unknown Server Error: ${error.message}`;
            }
        }
    }

    logout(): any {
        localStorage.removeItem(GlobalConstants.NameToken);
        return true;
    }

    //Método para guardar el token en el localStorage
    private saveToken(token: string): void {
        localStorage.setItem(GlobalConstants.NameToken, token);
    }

    private handlerError(err): Observable<never> {
        let errorMessage = "An error occurred retrienving data ";
        if (err) {
            errorMessage = `Error code${err.message}`;
            return throwError(errorMessage);
        }
    }

    obtenerUsuarioLogeado() {
        var token = localStorage.getItem(GlobalConstants.NameToken);
        const httpOptions = {
            headers: {
                "content-type": "application/json",
                Authorization: "Bearer " + token,
            },
        };

        return this.http
            .get<any>(
                GlobalConstants.apiURL + "api/getVerifiedUser",
                httpOptions
            )
            .pipe(
                map((resp: any) => {
                    return resp;
                }),
                catchError((err: any) => {
                    localStorage.removeItem(GlobalConstants.NameToken);
                    this._enrutador.navigateByUrl("/pages/auth/login");
                    return throwError(err["error"].message);
                })
            );
    }

    getPDF() {
        var token = localStorage.getItem(GlobalConstants.NameToken);
        const httpOptions = {
            headers: {
                "content-type": "application/json",
                Authorization: "Bearer " + token,
            },
        };

        return this.http
        .get<any>(
            GlobalConstants.apiURL + "damepdf",
            httpOptions
        )
        .pipe(
            map((resp: any) => {
                return resp;
            }),
            catchError((err: any) => {
                localStorage.removeItem(GlobalConstants.NameToken);
                this._enrutador.navigateByUrl("/pages/auth/login");
                return throwError(err["error"].message);
            })
        );
    }
}
