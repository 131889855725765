import { Injectable } from "@angular/core";
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from "@angular/router";
import { AutenticacionService } from "app/services/autenticacion.service";

@Injectable({
    providedIn: "root",
})
export class ControlAccesoGuard implements CanActivate {
    constructor(
        private router: Router,
        private _autenticacionService: AutenticacionService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        if(!this._autenticacionService.issLogged()){
            this.router.navigateByUrl("/pages/auth/login");
            return false;
        }

        return true;
    }
}
