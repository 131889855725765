import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subject } from "rxjs";
import { delay, filter, take, takeUntil } from "rxjs/operators";

import { FuseConfigService } from "@fuse/services/config.service";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FusePerfectScrollbarDirective } from "@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { AutenticacionService } from "app/services/autenticacion.service";
import { GlobalConstants } from "app/common/global-constants";
import { LoginComponent } from "app/main/pages/authentication/login/login.component";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "navbar-vertical-style-1",
    templateUrl: "./style-1.component.html",
    styleUrls: ["./style-1.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarVerticalStyle1Component implements OnInit, OnDestroy {
    userName: string;
    email: string;
    urlPhoto: any = "assets/images/avatars/profile.jpg";
    fuseConfig: any;
    navigation: any;

    // Private
    private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {Router} _router
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _router: Router,
        private _autenticacionService: AutenticacionService,
        private sanitizer: DomSanitizer,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        if (localStorage.getItem(GlobalConstants.NameToken) != null) {
            this._autenticacionService
                .obtenerUsuarioLogeado()
                .subscribe((res) => {
                    this.userName = res.nombre;
                    this.email = res.email;
                    this._autenticacionService.registerFullName(res.nombre);
                    this._autenticacionService.setCurrentFullName(res.nombre);
                    if (res.image) {
                        let objectURL = res.image;
                        this.urlPhoto =
                            this.sanitizer.bypassSecurityTrustUrl(objectURL);
                        this._autenticacionService.registerImage(objectURL);
                        this._autenticacionService.setCurrentImage(objectURL);
                    }
                });
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
      
        // Subscribe to the current image and name changes
        this._autenticacionService.onImageChanged.subscribe((res) => {
            // Load the Image
            this.urlPhoto = this._autenticacionService.getCurrentImage();
            // Mark for check
            this._changeDetectorRef.markForCheck();
        });

        this._autenticacionService.onFullNameChanged.subscribe((res) => {
            // Load the fullName
            this.userName = this._autenticacionService.getFullName();
            // Mark for check
            this._changeDetectorRef.markForCheck();
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Directive
    @ViewChild(FusePerfectScrollbarDirective, { static: true })
    set directive(theDirective: FusePerfectScrollbarDirective) {
        if (!theDirective) {
            return;
        }

        this._fusePerfectScrollbar = theDirective;

        // Update the scrollbar on collapsable item toggle
        this._fuseNavigationService.onItemCollapseToggled
            .pipe(delay(500), takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._fusePerfectScrollbar.update();
            });

        // Scroll to the active item position
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                setTimeout(() => {
                    this._fusePerfectScrollbar.scrollToElement(
                        "navbar .nav-link.active",
                        -120
                    );
                });
            });
    }

    ngOnInit(): void {
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                if (this._fuseSidebarService.getSidebar("navbar")) {
                    this._fuseSidebarService.getSidebar("navbar").close();
                }
            });

        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });

        // Get current navigation
        this._fuseNavigationService.onNavigationChanged
            .pipe(
                filter((value) => value !== null),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this.navigation =
                    this._fuseNavigationService.getCurrentNavigation();
            });
    }


    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    toggleSidebarOpened(): void {
        this._fuseSidebarService.getSidebar("navbar").toggleOpen();
    }

    toggleSidebarFolded(): void {
        this._fuseSidebarService.getSidebar("navbar").toggleFold();
    }
}
